import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_ENTERPRISE_URL = `${API_URL}/enterprises`

export async function editCustomer(api_token: string, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${API_URL}/customers`, Object, config)
}

export async function changePassword(api_token: string, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${API_URL}/auth/change-password`, Object, config)
}

export async function deleteCustomer(api_token: string) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` }  }; 
    return axios.delete(`${API_URL}/customers`, config)
}

// ENTERPRISES

export async function getEnterprises(api_token: string) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(GET_ENTERPRISE_URL, config)
}

export async function getSuscription (api_token: string) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${API_URL}/suscription/`, config)
}

export async function getReport (api_token: string) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${API_URL}/report/documents/`, config)
}

export async function getEnterpriseOne(api_token: string, enterpriseId: string) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` }  }; 
    return axios.get(GET_ENTERPRISE_URL+'/'+ enterpriseId, config)
}

export async function createEnterprise(api_token: string, enterpriseObject: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(GET_ENTERPRISE_URL, enterpriseObject, config)
}

export async function updateEnterprise(api_token: string, enterpriseId: string, enterpriseObject: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(GET_ENTERPRISE_URL+'/'+ enterpriseId, enterpriseObject, config)
}

export async function deleteEnterprise(api_token: string, enterpriseId: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` }  }; 
    return axios.delete(GET_ENTERPRISE_URL+'/'+ enterpriseId, config)
}

export async function updateAddress(api_token: string, enterpriseId: string, year: number, addressObject: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/address`, addressObject, config)
}

export async function updateDeadlines(api_token: string, enterpriseId: string, year: number, deadlinesObject: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/deadline`, deadlinesObject, config)
}

export async function updateAccounting(api_token: string, enterpriseId: string, year: number, accountingObject: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/accounting`, accountingObject, config)
}

export async function updateMailCLient(api_token: string, enterpriseId: string, year: number, mailclientObject: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/mailclient`, mailclientObject, config)
}

// DOCUMENT

export async function getDocuments(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/documents`, config)
}

export async function getOneDocuments(api_token: string, enterpriseId: number, year: number, id: number) {
    const config: AxiosRequestConfig = {
        responseType: 'blob' ,   
        headers: { Authorization: `Bearer ${api_token}` } 
    }; 
    const response:AxiosResponse<Blob> = await axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/documents/${id}`, config)
    return response
}

export async function editDocument(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/documents`, Object, config)
}

// CATEGORY

export async function createCategory(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/categories`, Object, config)
}

export async function getCategories(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/categories`, config)
}

export async function editCategory(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/categories`, Object, config)
}

export async function deleteCategory(api_token: string, enterpriseId: number, year: number, id: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.delete(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/categories/${id}`, config)
}

// SERIAL

export async function createInvoiceSerial(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/invoiceserials`, Object, config)
}

export async function getInvoiceSerials(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/invoiceserials`, config)
}

export async function editInvoiceSerial(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/invoiceserials`, Object, config)
}

export async function deleteInvoiceSerial(api_token: string, enterpriseId: number, year: number, id: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.delete(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/invoiceserials/${id}`, config)
}

// CLIENT

export async function createClient(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/clients`, Object, config)
}

export async function getClients(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/clients`, config)
}

export async function editClient(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/clients`, Object, config)
}

export async function deleteClient(api_token: string, enterpriseId: number, year: number, id: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.delete(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/clients/${id}`, config)
}

//  PRODUCT

export async function createProduct(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/products`, Object, config)
}

export async function getProducts(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/products`, config)
}

export async function editProduct(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/products`, Object, config)
}

export async function deleteProduct(api_token: string, enterpriseId: number, year: number, id: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.delete(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/products/${id}`, config)
}

//  INVOICE

export async function createInvoice(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/invoices`, Object, config)
}

export async function getInvoices(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/invoices`, config)
}

// PAYMENTS

export async function createPayment(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/payments`, Object, config)
}

export async function getPayments(api_token: string, enterpriseId: number, year: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.get(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/payments`, config)
}

export async function editPayment(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.patch(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/payments`, Object, config)
}

export async function deletePayment(api_token: string, enterpriseId: number, year: number, id: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.delete(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/payments/${id}`, config)
}

// BALANCES

export async function sendBalance(api_token: string, enterpriseId: number, year: number,  type: number, files: FormData) {
    const config = { headers: { Authorization: `Bearer ${api_token}`, 'Content-Type': 'multipart/form-data' } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/documents/${type}`, files, config)
}

// Permissions

export async function createPermission(api_token: string, enterpriseId: number, year: number, Object: Object) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.post(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/permissions`, Object, config)
}

export async function deletePermission(api_token: string, enterpriseId: number, year: number, id: number) {
    const config = {    headers: { Authorization: `Bearer ${api_token}` } }; 
    return axios.delete(`${GET_ENTERPRISE_URL}/${enterpriseId}/${year}/permissions/${id}`, config)
}