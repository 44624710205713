/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { PageTitle } from '../../../_metronic/layout/core'
//import { MixedWidget16 } from '../../../_metronic/partials/widgets'
import { KTSVG } from '../../../_metronic/helpers'
import { EnterprisesInit, UseEnterprise } from '../../modules/enterprises/core/Enterprise'
import { EmptyEnterprises } from '../../modules/enterprises/components/EmptyEnterprises'
import { EmptySuscription } from '../../modules/suscription/EmptySuscription'
import { Card6 } from '../../../_metronic/partials/content/cards/Card6'
import { accountingYear } from '../../modules/enterprises/core/_core'

type filter = {
  filterText: string, 
  onFilter: React.ChangeEventHandler<HTMLInputElement> ,
}

type NewButtonType = {
  plan: string
  rol: string
  enterprises: number
}

const FilterComponent = ({ filterText, onFilter }: filter) => {
  const intl = useIntl()
  return (
      <>
        <input
          id="search"
          type="text"
          className='form-control w-200px'
          placeholder={intl.formatMessage({id: 'FORM.FILTER'})}
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
        />

      </>
)
};

const NewButton = ({plan, rol, enterprises}: NewButtonType) => {
  const intl = useIntl()
  const [show, SetShow] = useState(false)

  useEffect(() => {
    if(rol === "Business"){
      if(plan === "Client" && enterprises < 3) SetShow(true)
      if(plan === "Basic" && enterprises < 10) SetShow(true)
      if(plan === "Expert" && enterprises < 50) SetShow(true)
      if(plan === "Premium" && enterprises < 150) SetShow(true)
    }
  }, []); 

  if(!show) return <></>
  else{
    return <>
                <div className=''>
                  <Link
                    to={`/new-enterprise`}
                    className='btn btn-primary btn-sm'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    {intl.formatMessage({id: 'ENTERPRISE.NEW'})}
                  </Link>
            </div>
    </>
  }

}

const EnterprisesPage: FC = () => {
  const intl = useIntl()
  const { auth} = useAuth() 
  const { enterprises, currentSuscription } = UseEnterprise()
  const [ filterText, setFilterText] = useState('');
  const arrayEnterprises = enterprises?.sort((a, b) => a.id - b.id)
  let filteredItems = arrayEnterprises.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  return (
  <>
    
    <div className='d-flex flex-wrap flex-stack mb-6'>
      <h3 className='fw-bolder my-2'>
        {intl.formatMessage({id: 'DASHBOARD.TITLE'})}
      </h3>

      <div className='d-flex flex-wrap my-2'>
        <div className='pe-5 pb-5'>
          <FilterComponent
              onFilter={(event) => setFilterText(event.target.value)} 
              filterText={filterText} 
          />
        </div>
        {
          (auth && currentSuscription)? (
            <>
              <NewButton 
                plan={currentSuscription.plan}
                rol={auth.role}
                enterprises={enterprises.length}
             />
            </>
          ):(
            <></>
          )
        }
        
      </div>
    </div>

    {(enterprises.length === 0) && <EmptyEnterprises  />}

    <div className='row g-6 g-xl-9'>
      {filteredItems.map(enterprise => (  
          <div className='col-md-6 col-xl-4' key={enterprise.id}>
            <Card6
              icon={ (enterprise.type.id === 1)? '/media/png/person-line-96.png' : '/media/png/enterprise-line-96.png' }
              badgeColor={ (enterprise.type.id === 1)? 'primary' : 'success' }
              status={enterprise.type.name}
              statusColor={ (enterprise.type.id === 1)? 'primary' : 'success' }
              title={enterprise.name}
              nif={enterprise.nif}
              income={ accountingYear(enterprise, 2024)?.income || 0 }
              expenses={ accountingYear(enterprise, 2024)?.expenses || 0 }
              total={ accountingYear(enterprise, 2024)?.total || 0 }
              enterpriseId={enterprise.id}
              year={2024}
              active={enterprise.active}
            />
          </div>
        ))}
    </div>
  </>
  )
}

const EnterprisesWrapper: FC = () => {
  const intl = useIntl()
  const { auth } = useAuth() 
  const { currentSuscription } = UseEnterprise()
  const [showSuscription, setShowSuscription] = useState(false)


  useEffect(() => {
    if (auth?.role === "Business" && !currentSuscription?.id) setShowSuscription(true)
  }, []); 
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
     
        {showSuscription? (
          <EmptySuscription />
        ):(
          <EnterprisesInit>
            <EnterprisesPage />
          </EnterprisesInit>
        ) }
     
    </>
  )
}

export {EnterprisesWrapper}
