/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import {KTSVG} from '../../../../../../_metronic/helpers'
//import { UseEnterprise } from '../../../../enterprises/core/Enterprise'
import { useAuth } from '../../../../auth'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

const CardForm: React.FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  // const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();
    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('Stripe.js has not yet loaded.');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.log('Card element not found.');
      setLoading(false);
      return;
    }

    const {error: backendError, clientSecret} = await fetch(
      '/create-payment-intent',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodType: 'card',
          currency: 'usd',
        }),
      }
    ).then((r) => r.json());

    if (backendError) {
      console.log(backendError.message);
      return;
    }

    console.log('Client secret returned');

    const {error: stripeError, paymentIntent} = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'Jenny Rosen',
          },
        },
      }
    );

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(stripeError.message);
      return;
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
    console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

    setLoading(false)
  };


  return (
      <div className=''>
        <form id="payment-form"   
            onSubmit={handleSubmit}
            >
              <div className='card-title m-0'>
                <h3>Card</h3>
              </div>
              <div className='card-body border-top p-9'>
                <CardElement id="card" />
              </div>
          
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && intl.formatMessage({id: 'FORM.SAVE'})}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'FORM.WAIT'})}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
      </div>
  )
}

export {CardForm}
