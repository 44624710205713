import React from 'react'
import { useIntl } from 'react-intl'
import { CardForm } from './cards/CheckoutForm'
import { loadStripe } from '@stripe/stripe-js';
import { Elements} from '@stripe/react-stripe-js';
// Carga la clave pública de Stripe
const stripePromise = loadStripe('pk_test_51PBGA5DwNCQGVNeqw6D5iZjWFXzjZQDq1aPyzc0ZmiZZC7pJcxR5E4Pj4IyVuNK9qd915jiHSyRhbuTDqNA7dnpT00QtanM0WR');

// import {SignInMethod} from './cards/SignInMethod'
// import {ChangePassword} from './cards/ChangePassword'
// import {ConnectedAccounts} from './cards/ConnectedAccounts'
// import {EmailPreferences} from './cards/EmailPreferences'
// import {Notifications} from './cards/Notifications'
// import {DeactivateAccount} from './cards/DeactivateAccount'

export function CheckoutPage() {
    const intl = useIntl()

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Checkout</span>
                    </h3>
                    <div className='card-toolbar'>

                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='row mb-6'>
                        <div className='col-lg-6'>
                        <div className="d-flex h-100 align-items-center">
                                  <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                      <div className="mb-7 text-center">
                                          <h1 className="text-gray-900 mb-5 fw-bolder">Basic</h1>
                                          <div className="text-gray-600 fw-semibold mb-5">
                                              Optimal for 10+ team size<br/> and new startup                                                 
                                          </div>
                                          <div className="text-center">
                                              <span className="mb-2 text-primary">$</span>
                                              <span className="fs-3x fw-bold text-primary" data-kt-plan-price-month="39" data-kt-plan-price-annual="399">
                                                  25
                                              </span>
                                              <span className="fs-7 fw-semibold opacity-50">/ 
                                                  <span data-kt-element="period">Mon</span>
                                              </span>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                        </div>

                        <div className='col-lg-6'>
                            <Elements stripe={stripePromise}>
                                <CardForm />
                            </Elements>
                        </div>

                    </div>
                
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}