/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC} from 'react'
//import { useIntl} from 'react-intl'
//import { PageTitle} from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { ReportInit, UseEnterprise } from '../../modules/enterprises/core/Enterprise'
import { Link } from 'react-router-dom'


const ReportPage: FC = () => {
  const { currentUser } = useAuth() 
  const { currentReport } = UseEnterprise()
  const enterprisesLength = currentReport?.documents?.enterprises?.length ?? 0;
  const year = new Date().getFullYear() ;

  return  (
    <>
      <div className="row gy-5 g-xl-8">

            <div className="col-xl-4 mb-xl-6">
              <div className="card card-flush h-xl-100">
                <div  
                  className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px" 
                  style={{ backgroundImage: "url('/media/svg/shapes/top-green.png')"}}
                  data-theme="light">
                  <h3 className="card-title align-items-start flex-column text-white pt-15">
                    <span className="fw-bold fs-2x mb-3">Hello, {currentUser?.customer.name}</span>
                    <div className="fs-4 text-white">
                      <span className="opacity-75">Tienes </span>
                    </div>
                  </h3>
                </div>

                <div className="card-body mt-n20">
                  <div className="mt-n20 position-relative">
                    <div className="row g-3 g-lg-6">
                      <div className="col-6">
                        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                          <div className="symbol symbol-30px me-5 mb-8">
                            <span className="symbol-label">
                              {/* <!--begin::Svg Icon | path: icons/duotune/medicine/med005.svg--> */}
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M17.9061 13H11.2061C11.2061 12.4 10.8061 12 10.2061 12C9.60605 12 9.20605 12.4 9.20605 13H6.50606L9.20605 8.40002V4C8.60605 4 8.20605 3.6 8.20605 3C8.20605 2.4 8.60605 2 9.20605 2H15.2061C15.8061 2 16.2061 2.4 16.2061 3C16.2061 3.6 15.8061 4 15.2061 4V8.40002L17.9061 13ZM13.2061 9C12.6061 9 12.2061 9.4 12.2061 10C12.2061 10.6 12.6061 11 13.2061 11C13.8061 11 14.2061 10.6 14.2061 10C14.2061 9.4 13.8061 9 13.2061 9Z" fill="currentColor"></path>
                                  <path d="M18.9061 22H5.40605C3.60605 22 2.40606 20 3.30606 18.4L6.40605 13H9.10605C9.10605 13.6 9.50605 14 10.106 14C10.706 14 11.106 13.6 11.106 13H17.8061L20.9061 18.4C21.9061 20 20.8061 22 18.9061 22ZM14.2061 15C13.1061 15 12.2061 15.9 12.2061 17C12.2061 18.1 13.1061 19 14.2061 19C15.3061 19 16.2061 18.1 16.2061 17C16.2061 15.9 15.3061 15 14.2061 15Z" fill="currentColor"></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="m-0">
                            <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">{currentReport?.enterprises.total}</span>
                            <span className="text-gray-500 fw-semibold fs-6">Total</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                          <div className="symbol symbol-30px me-5 mb-8">
                            <span className="symbol-label">
                              {/* <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg--> */}
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"></path>
                                  <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="m-0">
                            <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">{currentReport?.enterprises.active}</span>
                            <span className="text-gray-500 fw-semibold fs-6">Activa</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-8 '>
              <div className='row '> 
                <div className="col-sm-6 col-xl-6 mb-xl-6">
                  <div className="card ">
                    <div className="card-body d-flex justify-content-between flex-column">
                      <div className="m-0">
                        <KTSVG path={'/media/icons/duotune/files/fil003.svg'} className='svg-icon svg-icon-2hx svg-icon-primary' />
                      </div>
                      <div className="d-flex justify-content-end mt-7">
                        <div className="text-end ml-auto">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{currentReport?.documents.total}</span>
                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">Total</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-6 mb-xl-6">
                  <div className="card ">
                    <div className="card-body d-flex justify-content-between flex-column">
                      <div className="m-0">
                        <KTSVG path={'/media/icons/duotune/files/fil003.svg'} className='svg-icon svg-icon-2hx svg-icon-success' />
                      </div>
                      <div className="d-flex justify-content-end mt-7">
                        <div className="text-end ml-auto">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{currentReport?.documents.accounted}</span>
                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">Contabilizados</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-6 mb-xl-6">
                  <div className="card ">
                    <div className="card-body d-flex justify-content-between flex-column">
                      <div className="m-0">
                        <KTSVG path={'/media/icons/duotune/files/fil003.svg'} className='svg-icon svg-icon-2hx svg-icon-warning' />
                      </div>
                      <div className="d-flex justify-content-end mt-7">
                        <div className="text-end ml-auto">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{currentReport?.documents.processes}</span>
                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">Procesando</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-6 mb-xl-6">
                  <div className="card ">
                    <div className="card-body d-flex justify-content-between flex-column">
                      <div className="m-0">
                        <KTSVG path={'/media/icons/duotune/files/fil003.svg'} className='svg-icon svg-icon-2hx svg-icon-danger' />
                      </div>
                      <div className="d-flex justify-content-end mt-7">
                        <div className="text-end ml-auto">
                          <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{currentReport?.documents.rejected}</span>
                          <div className="m-0">
                            <span className="fw-semibold fs-6 text-gray-400">Rechazados</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
      </div>

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-8'>

          <div className='card card-xxl-stretch mb-5 mb-xxl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Documentos pendientes</span>
                <span className='text-muted mt-1 fw-semobold fs-7'>Los empresa con documentos pendientes de procesar</span>
              </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='tab-content'>
                {/* begin::Tap pane */}
                <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='border-0'>
                          <th className='p-0 w-50px'></th>
                          <th className='p-0 min-w-150px'></th>
                          <th className='p-0 min-w-140px'></th>
                          <th className='p-0 min-w-110px'></th>
                          <th className='p-0 min-w-50px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>

                        {enterprisesLength === 0 && (
                          <tr>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Todas las empresas están al día</span>
                            </td>
                          </tr>
                        )}

                        {currentReport?.documents.enterprises.map(enterprise => (  
                          <tr key={enterprise.id}>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={(enterprise.type === 1)? toAbsoluteUrl('/media/png/person-line-96.png') : toAbsoluteUrl('/media/png/enterprise-line-96.png')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {enterprise.name}
                              </a>
                              <span className='text-muted fw-semobold d-block'>{enterprise.nif}</span>
                            </td>
                            <td className='text-end text-muted fw-semobold'>{enterprise.process}</td>
                            <td className='text-end'>
                              <span className='badge badge-light-warning'>In Progress</span>
                            </td>
                            <td className='text-end'>
                              <Link
                                to={`/enterprise/${enterprise.id}/${year}/overview`}
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </Link>
                            </td>
                          </tr>
                        ))}

                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Tap pane */}
                {/* begin::Tap pane */}
                <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='border-0'>
                          <th className='p-0 w-50px'></th>
                          <th className='p-0 min-w-150px'></th>
                          <th className='p-0 min-w-140px'></th>
                          <th className='p-0 min-w-110px'></th>
                          <th className='p-0 min-w-50px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              Brad Simmons
                            </a>
                            <span className='text-muted fw-semobold d-block'>Movie Creator</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>React, HTML</td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>Approved</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              Popular Authors
                            </a>
                            <span className='text-muted fw-semobold d-block'>Most Successful</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>Python, MySQL</td>
                          <td className='text-end'>
                            <span className='badge badge-light-warning'>In Progress</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              Active Customers
                            </a>
                            <span className='text-muted fw-semobold d-block'>Movie Creator</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>AngularJS, C#</td>
                          <td className='text-end'>
                            <span className='badge badge-light-danger'>Rejected</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Tap pane */}
                {/* begin::Tap pane */}
                <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='border-0'>
                          <th className='p-0 w-50px'></th>
                          <th className='p-0 min-w-150px'></th>
                          <th className='p-0 min-w-140px'></th>
                          <th className='p-0 min-w-110px'></th>
                          <th className='p-0 min-w-50px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              Bestseller Theme
                            </a>
                            <span className='text-muted fw-semobold d-block'>Best Customers</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>ReactJS, Ruby</td>
                          <td className='text-end'>
                            <span className='badge badge-light-warning'>In Progress</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              Active Customers
                            </a>
                            <span className='text-muted fw-semobold d-block'>Movie Creator</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>AngularJS, C#</td>
                          <td className='text-end'>
                            <span className='badge badge-light-danger'>Rejected</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              New Users
                            </a>
                            <span className='text-muted fw-semobold d-block'>Awesome Users</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>Laravel,Metronic</td>
                          <td className='text-end'>
                            <span className='badge badge-light-primary'>Success</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                  className='h-50 align-self-center'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              Popular Authors
                            </a>
                            <span className='text-muted fw-semobold d-block'>Most Successful</span>
                          </td>
                          <td className='text-end text-muted fw-semobold'>Python, MySQL</td>
                          <td className='text-end'>
                            <span className='badge badge-light-warning'>In Progress</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                  {/* end::Table */}
                </div>
                {/* end::Tap pane */}
              </div>
            </div>
            {/* end::Body */}
          </div>


        </div>
      </div>


    </>
  )
}

const ReportWrapper: FC = () => {
  //const intl = useIntl()

  return (
    <>
      <ReportInit>
        <ReportPage />
      </ReportInit>
    </>
  )
}

export {ReportWrapper}
