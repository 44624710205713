import { FC } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useAuth } from '../../auth'

const EmptyEnterprises: FC = () => {
    const intl = useIntl()
    const { currentUser } = useAuth() 

    const ButtonOrText = () => {
        if (currentUser?.role === 'Business') {
            return (                        
                <div className="mb-4">
                    <Link to="/new-enterprise" className="btn btn-sm btn-primary">{intl.formatMessage({id: 'ENTERPRISE.NEW'})}</Link>
                </div>   
            )
        }else{
            return (
                <p className="fs-4">Ponte en contacto con tu Asosor para conectar tu empresa</p>
            )
        }
    }

    return (
        <>
            <div  className="card card-flush text-center py-5 mb-8">
                <div className="card-body py-5">
                    <div className="mb-0">
                        <img src={toAbsoluteUrl('/media/auth/membership_w.png')} className="mw-100 mh-300px theme-light-show" alt=""/>
                    </div>

                    <h1 className="fw-bolder text-gray-900 mb-5">
                        {intl.formatMessage({id: 'ENTERPRISE.NEW.TEXT'})}
                    </h1>
                    {ButtonOrText()}
          
                </div>
            </div>
        </>
    )
}

export {EmptyEnterprises};