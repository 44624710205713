import { FC } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

const EmptySuscription: FC = () => {
    const intl = useIntl()
    return (
        <>
            <div  className="card card-flush text-center py-5 mb-8">
                <div className="card-body py-5">
                    <div className="mb-10">
                        <img alt='Upgrade' src={toAbsoluteUrl('/media/illustrations/misc/upgrade.svg')} className="mw-100 mh-300px theme-light-show" />
                    </div>

                    <h1 className="fw-bolder text-gray-900 mb-5">
                        {intl.formatMessage({id: 'SUSCRIPTION.TEXT'})}
                    </h1>
            
                    <div className="mb-4">
                        <Link to="/suscription" className="btn btn-sm btn-primary">{intl.formatMessage({id: 'SUSCRIPTION.BUTTON'})}</Link>
                    </div>   
          
                </div>
            </div>
        </>
    )
}

export {EmptySuscription};