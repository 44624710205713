/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuProfile() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/report'
        icon='/media/icons/duotune/general/gen032.svg'
        title={intl.formatMessage({id: 'MENU.REPORT'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/account/settings'
        title={intl.formatMessage({id: 'MENU.PROFILE'})}
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      />

      {currentUser?.role === 'Business' && (
        <>
            <AsideMenuItem
              to='/suscription'
              title={intl.formatMessage({id: 'SUSCRIPTION.TITLE'})}
              icon='/media/icons/duotune/finance/fin002.svg'
              fontIcon='bi-person'
            />
        </>
      )}
    </>
  )
}
