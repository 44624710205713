/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
// import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
// import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {toAbsoluteUrl, KTSVG} from '../../../helpers'

type Props = {
  icon: string
  badgeColor: string
  status: string
  statusColor: string
  title: string
  nif: string
  income: number
  expenses: number
  total: number
  enterpriseId?: number
  year?: number
  active: boolean
}

const Card6: FC<Props> = ({ icon, badgeColor, status, statusColor, title, nif, income, expenses, total, enterpriseId, year, active}) => {
  const intl = useIntl()
  let url = ''
  if(active) url = `/enterprise/${enterpriseId}/${year}/overview`

  return (
    <Link
      to={url}
      className='card border border-2 border-gray-300 border-hover'
    >
      <div className='card-header border-0 pt-4'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={toAbsoluteUrl(icon)} alt='card2' className='p-3' />
          </div>
        </div>

        <div className='card-toolbar'>
          <div className='w-full'>
              {!active? (
                <span className="badge badge-light-danger fw-bolder me-auto px-4 py-3">{intl.formatMessage({id: 'SUSCRIPTION.SUSPENDED'})}</span>
              ):(
                <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
                {nif}
                </span>
              )}

          </div>
          {/* <div className='fs-5 fw-bolder text-gray-600 mb-3'>{nif}</div> */}
        </div>
      </div>

      <div className='card-body py-4'>
        <div className='fs-3 text-dark'>{title}</div>
        <div className='fs-8 text-gray-600'>{status}</div> 
        <div className='d-flex flex-wrap '>

          {/* begin::Stats */}
          <div className=' pt-5 pb-0 flex-grow-1'>

            {/* begin::Row */}
            <div className='row g-0 mt-5 mb-2'>
              {/* begin::Col */}
              <div className='col-12 mb-4'>
                <div className='d-flex justify-content-between me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-info'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra004.svg'
                        className='svg-icon-1 svg-icon-info'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bold'>{parseFloat(income.toString()).toFixed(2).toString().replace('.',',')} €</div>
                    <div className='fs-7 text-muted fw-semobold text-end'>Income</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col-12 mb-4'>
                <div className='d-flex justify-content-between me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-danger'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra005.svg'
                        className='svg-icon-1 svg-icon-danger'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bold'>{parseFloat(expenses.toString()).toFixed(2).toString().replace('.',',')} €</div>
                    <div className='fs-7 text-muted fw-semobold text-end'>Expenses</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col-12 mb-4'>
                <div className='d-flex justify-content-between me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-success'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra012.svg'
                        className='svg-icon-1 svg-icon-success'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bold'>{parseFloat(total.toString()).toFixed(2).toString().replace('.',',')} €</div>
                    <div className='fs-7 text-muted fw-semobold text-end'>Total</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}
            </div>
            {/* end::Row */}

          </div>
          {/* end::Stats */}
          
        </div>

        {/* <a href={url} className='btn btn-sm btn-light'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          View
        </a> */}

      </div>
    </Link>
  )
}

export {Card6}
