/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { UseEnterprise } from '../../../../app/modules/enterprises/core/Enterprise'

export function AsideMenuEnterprise() {
  const intl = useIntl()
  const { currentEnterprise, currentYear, showAsideMenuEnterprise } = UseEnterprise()



  if(showAsideMenuEnterprise && currentEnterprise?.id ){

    const id:number = currentEnterprise?.id 
    const  year: number = currentYear || 2024
    const url = `/enterprise/${id}/${year}`

    return <>
              <AsideMenuItem
                to={url+'/overview'}
                icon='/media/icons/duotune/finance/fin006.svg'
                title={intl.formatMessage({id: 'MENU.HOME'})}
                fontIcon='bi-app-indicator'
              />

              <AsideMenuItem
                to={url+'/documents'}
                icon='/media/icons/duotune/files/fil003.svg'
                title={intl.formatMessage({id: 'MENU.DOCUMENTS'})}
                fontIcon='bi-app-indicator'
              />

              <AsideMenuItem
                to={url+'/clients'}
                icon='/media/icons/duotune/communication/com014.svg'
                title={intl.formatMessage({id: 'MENU.CLIENTS'})}
                fontIcon='bi-app-indicator'
              />

              <AsideMenuItemWithSub
                to={url+'/products'}
                title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
                icon='/media/icons/duotune/ecommerce/ecm005.svg'
                fontIcon='bi-person'
              >
                <AsideMenuItem 
                  to={url+'/products'} 
                  title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
                  hasBullet={true} 
                />

                <AsideMenuItem 
                  to={url+'/categories'} 
                  title={intl.formatMessage({id: 'MENU.CATEGORIES'})}
                  hasBullet={true} 
                />

              </AsideMenuItemWithSub>

              <AsideMenuItemWithSub
                to={url+'/invoices'}
                title={intl.formatMessage({id: 'MENU.INVOICES'})}
                icon='/media/icons/duotune/ecommerce/ecm008.svg'
                fontIcon='bi-person'
              >
                <AsideMenuItem 
                  to={url+'/invoices'} 
                  title={intl.formatMessage({id: 'MENU.INVOICES'})}
                  hasBullet={true} 
                />

                <AsideMenuItem 
                  to={url+'/serials'} 
                  title={intl.formatMessage({id: 'MENU.SERIES'})}
                  hasBullet={true} 
                />

                <AsideMenuItem 
                  to={url+'/payments'} 
                  title={intl.formatMessage({id: 'MENU.PAYMENTS'})}
                  hasBullet={true} 
                />
                
              </AsideMenuItemWithSub>
 
    </>
  } 
  else{
        return (  <> </> )
    }
}
