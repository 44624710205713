import { EnterpriseModel } from "./_models";


export function formattedDate(deadline: string) {

    const date = new Date(deadline);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}

export const accountingYear = (enterprises: EnterpriseModel, Year: number) => {
    const accountingYear = enterprises.accountings.find(item => item.year === Year)
    return accountingYear
  }