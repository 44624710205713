import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`

// export const LOGIN_URL = `${API_URL}/login`
export const LOGIN_URL = `${API_URL}/auth/login`

export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/recovery`
export const REQUEST_LOSTPASSWORD_URL = `${API_URL}/auth/lost-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function login2(email: string, password: string) {
  return axios.post<UserModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  phone: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    name: firstname,
    lastName: lastname,
    phone: phone,
    user: {
      email,
      password,
    }
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}


export function lostPassword(token: string, password: string){
  return axios.post(REQUEST_LOSTPASSWORD_URL, {
    token: token,
    password: password
  })
}
