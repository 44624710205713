import React, {useState} from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { createEnterpriseModel, enterprises_type, country_list } from '../../core/_models'
import { createEnterprise } from '../../core/_requests'
import { useAuth } from '../../../auth'
import { UseEnterprise } from '../../core/Enterprise'

const enterpriseDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Enterprise name is required'),
  nif: Yup.string().required('NIF name is required').min(5),
  description: Yup.string().required('Description is required'),
  phone: Yup.number().integer().required('Phone is required'),
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  cp: Yup.number().integer().required('Postal Code is required'),
})

const NewEnterprise: React.FC = () => {
  const intl = useIntl()
  const { auth } = useAuth() 
  const { currentSuscription } = UseEnterprise()
  const navigate = useNavigate();

  const initialValues = {
    name:  '',
    nif:  '',
    typeId: 1,
    description:  '',
    phone:  987654321,
    street:  "",
    city:  "",
    cp:  "",
    state:  "",
    country:  "ES",  
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<createEnterpriseModel>({
    initialValues,
    validationSchema: enterpriseDetailsSchema,
    onSubmit:async (values) =>  {
      if (auth && currentSuscription){
        setLoading(true)

        const captureValues = {
          nif: values.nif,
          name: values.name,
          typeId: values.typeId, 
          description: values.description,
          phone: values.phone, 
          address:{
            street: values.street,
            city: values.city,
            cp: values.cp, 
            state: values.state,
            country: values.country,
          }
         }
     
        await createEnterprise(auth.api_token, captureValues)
        navigate('/dashboard');         
        setLoading(false)
      }
    },
  })
  
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'ENTERPRISE.NEW'})}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                  ></div>
                </div>
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'FORM.NAME'})}</label>
              <div className='col-lg-8'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Enterprise name'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'FORM.NIF'})}</label>
              <div className='col-lg-8'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='12345678X'
                      {...formik.getFieldProps('nif')}
                    />
                    {formik.touched.nif && formik.errors.nif && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.nif}</div>
                      </div>
                    )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'FORM.TYPE'})}</label>

              <div className='col-lg-8 fv-row'>
                  <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps("typeId")}
                    >
                      {
                        enterprises_type.map(item => (
                          <option 
                            key={item.code}
                            value={item.code} 
                          >
                              {item.name}
                          </option>
                        ))
                      }
                    </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'FORM.PHONE'})}</label>
              <div className='col-lg-8'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Phone'
                      {...formik.getFieldProps('phone')}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phone}</div>
                      </div>
                    )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'FORM.DESCRIPTION'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  rows={4}
                  {...formik.getFieldProps('description')}
                >
                </textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'FORM.STREET'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Street'
                  {...formik.getFieldProps('street')}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.street}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'FORM.CITY'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'FORM.PC'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postal Code'
                  {...formik.getFieldProps('cp')}
                />
                {formik.touched.cp && formik.errors.cp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cp}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'FORM.STATE'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('state')}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.state}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({id: 'FORM.COUNTRY'})}</label>

              <div className='col-lg-8 fv-row'>
                  <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps("country")}
                    >
                      {
                        country_list.map(item => (
                          <option 
                            key={item.code}
                            value={item.code} 
                          >
                              {item.name}
                          </option>
                        ))
                      }
                    </select>
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && intl.formatMessage({id: 'FORM.CREATE'})}
              {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'FORM.WAIT'})}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { NewEnterprise }
